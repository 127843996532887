import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CountdownTimer from './CountdowmTimer';
import {ReactComponent as ByXirifySVG} from '../../images/ByXirify.svg';
import {ReactComponent as Advance} from "../../images/Advance.svg";
import {ReactComponent as Green_AP} from "../../images/Green_AP.svg";
import { Grid } from '@material-ui/core';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

    //{ id: 'serviceRequestId', disablePadding: true, label: 'Service Request Id' },
    { id: 'conversation', disablePadding: true, label: 'Message Count' },
    { id: 'serviceRequestId', disablePadding: true, label: 'Service Request ID' },
    { id: 'paymentStatus', disablePadding: true, label: 'Payment Status' },    
    { id: 'consumerData[0].firstName', disablePadding: true, label: 'Consumer Name' },
    { id: 'orderCount', disablePadding: true, label: 'Orders on Xirify' },
    { id: 'creationDate', disablePadding: false, label: 'Request Date' },
    { id: 'costType', disablePadding: false, label: 'Cost Type' },
    { id: 'amount', disablePadding: false, label: 'Amount' },
    { id: 'currentStatus', disablePadding: false, label: 'Current Status' },
    // { id: 'businessId', disablePadding: false, label: 'Business Id' },
    {id:'serviceMode',disablePadding:false,label:'Service Mode'},
    { id: 'businessName/Id', disablePadding: false, label: 'Business Name/Id' },
    
    
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        if(property !== "orderCount")
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            {props.isPreServiceRequst ?
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
           Preservice Requests ({props.total}) &nbsp; &nbsp; &nbsp; Total Amount : {props.totalAmount} &nbsp;
           </Typography> :
 
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Service Requests ({props.total}) &nbsp; &nbsp; &nbsp; Total Amount : {props.totalAmount} &nbsp;
            </Typography>
            }
            <Grid xs={3}>
            <Typography component="div">
                {props.showDownloadIconForItemReport ?
                    <CSVLink
                        data={props.itemReportData}
                        headers={props.headersItemReport}
                        filename={"item_level_report.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>

                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchOrdersByQueryItemReportExcelFile}
                    >
                        ItemReport
               </Button>
                }
            </Typography>
            </Grid>
            <Grid xs={2}>          
            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"all_service_requests.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>

                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchOrdersByQueryExcelFile}
                    >
                        Export
               </Button>
                }
            </Typography>
           </Grid>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        cursor: 'pointer'
    },
    preparationTimer: {
        color: "#FF0000",
      }
      
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    let currentTime = (new Date().toISOString());	

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const redirectToDetails = (serviceRequestData) => {
       
        props.history.push(`/servicerequests/${serviceRequestData._id}`, props.isPreServiceRequst);
    
    }

    const getPaymentStatusInitial = (paymentStatus) => {
        let statusInitial = "UNPAID"
        if (paymentStatus === "PAID") {
            statusInitial = "PAID"
        }
        if (paymentStatus === "INITIATED") {
            statusInitial = "INITIATED"
        }
        if (paymentStatus === "INPROGRESS") {
            statusInitial = "IN PROGRESS"
        }
        return statusInitial;
    }

    const getServiceMode = (serviceMode,deliveryType)=>{
        let serviceM="";

        if(deliveryType === 'dunzo' ){
            serviceM = 'DELIVERY-(dunzo)'
        }
        if( deliveryType === 'borzo' ){
            serviceM = 'DELIVERY-(borzo)'
        }
        if(deliveryType === 'xirify_delivery' ){
            serviceM = 'DELIVERY'
        }
        if(deliveryType === 'porter' ){
            serviceM = 'DELIVERY-(porter)';
        }
        if(deliveryType === 'shadowfax' ){
            serviceM = 'DELIVERY-(shadowfax)';
        }
        if( deliveryType === 'self' && serviceMode === 'DELIVERY' ){
            serviceM = 'DELIVERY-(self)'
        }else if(deliveryType === 'self' && serviceMode === 'PICKUP'){
            serviceM = 'PICKUP'
        }else if(serviceMode === 'PICKUP'){
            serviceM = 'PICKUP'
        }else if(serviceMode === 'SERVICE_AT_BUSINESS_LOCATION' && deliveryType === 'self' ){
            serviceM = 'Business Location'
        }else if(serviceMode === 'SERVICE_AT_CONSUMER_LOCATION' && deliveryType === 'self' ){
            serviceM = 'My Location'
        }
        return serviceM;
    }

    const calculateSubtraction = (totalAmount,couponAmount) => {
        let amount = totalAmount - couponAmount;
        amount = amount.toFixed(2);
        return amount;
    }

    let headers = [
        { label: 'Service Request Id', key: 'serviceRequestId' },
        { label: 'Consumer Id', key: 'consumerId' },
        { label: 'Consumer Name', key: 'consumerName' },
        {label: 'Consumer Mobile Number', key:'consumerMobileNumber'},
        { label: 'Pin Code', key: 'pCode' },
        { label: 'Orders on Xirify', key: 'orderCount' },
        { label: 'Request Date', key: 'creationDate' },
        { label: 'Cost Type', key: 'costType' },
        { label: 'Amount', key: 'SRAmount' },
        { label: 'Service Mode', key: 'service' },
        { label: 'Current Status', key: 'currentStatus' },
        { label: 'Status Changed On', key: 'statusChangedOn' },
        { label: 'Business Id', key: 'businessId' },
        { label: 'Business Name', key: 'businessName' },
        { label: 'Account Manager Name', key: 'accountManager' },
        { label: 'Account Manager Conatact Number', key: 'accountManagerNumber' },
        { label: 'Payment Status', key: 'paymentStatus' },
        { label: 'SR Coupon Status', key: 'srCStatus' },
        { label: 'Coupon Code', key: 'couponCode' },
        { label: 'Coupon Amount', key: 'couponAmount' },
        { label: 'Cancelled By', key: 'cancelledBy' },
        { label: 'Reason for Cancellation', key: 'option' }
    ];
    let headersItemReport = [
        { label: 'Service Request Id', key: 'serviceRequestId' },  
        { label: 'Consumer Name', key: 'consumerName' },
        { label: 'Service Mode', key: 'serviceMode' },
        { label: 'Created Time', key:'creationDate'},
        { label: 'Order Status', key: 'orderStatus' },
        { label: 'Delivery Done Time', key: 'deliveryDoneTime' },
        { label: 'Business Id', key: 'businessId' },
        { label: 'Business Name', key: 'businessName' },
        { label: 'Request Item Name', key: 'RequestedItemName' },
        { label: 'Quantity', key: 'quantity' },
        { label: 'Unit', key: 'unit' },
        { label: 'RatePerUnit', key: 'ratePerUnit' },
        // { label: 'Discount Offer', key: 'discountOffer' },
        { label: 'QTY Added', key: 'qtyAdded' },      
        { label: 'Price', key: 'fixedCost' },
        { label: 'Availablility', key: 'isAvailable' },
       
    ];
    let data  = props.exportToexcel;
    data && data.forEach(sr => {       
        sr.creationDate = moment(sr.creationDate).format('DD MMM YYYY hh:mm A');
        let actionTime = sr.statusHistory.filter((history) => {
            return history.status === sr.currentStatus;
        })[0];
        sr.statusChangedOn = (actionTime && actionTime.time && actionTime.time !== undefined) ? moment(actionTime.time).format('DD MMM YYYY hh:mm A') : "";
        // sr.costType = sr.services.length > 0 ? sr.services[0].cost.costType : "Quick Request";
        sr.costType = sr.services && sr.services !== null && sr.services !== undefined ? sr.services.length > 0 ? sr.services[0].cost.costType : "Quick Request" : "NA";
        // sr.consumerName = sr.consumerData[0].firstName + " " + sr.consumerData[0].lastName;
        // sr.consumerId = sr.consumerData[0].consumerId;
        // sr.orderCount = sr.consumerData[0].ordersAtXirify;
        // sr.amount = (sr.couponData && (sr.couponData.length>0) && sr.couponData[0].amount && (sr.couponData[0].status == 'Consumed' || sr.couponData[0].status == 'PayBackSeller') && sr.amount && (sr.amount != null || sr.amount != 'Not Set' || sr.amount != undefined) && (sr.amount > sr.couponData[0].minOrderValue) ) ? calculateSubtraction(sr.amount,sr.couponData[0].amount) :  (sr.amount ? sr.amount : "Not Set")
        sr.consumerName = sr.consumerDetails ? (sr.consumerDetails.firstName || sr.consumerDetails.lastName ? sr.consumerDetails.firstName + " " + sr.consumerDetails.lastName : "NA")  : "NA" ;
        sr.consumerMobileNumber = sr.consumerDetails  ? sr.consumerDetails.primaryMobileNumber : "NA";
        sr.consumerId = sr.consumerDetails && sr.consumerDetails.consumerId ? sr.consumerDetails.consumerId  : "NA" ;
        sr.accountManager = sr.accountManagerDetails ? `${(sr.accountManagerDetails.firstName).charAt(0).toUpperCase()+(sr.accountManagerDetails.firstName).slice(1)} ${(sr.accountManagerDetails.lastName).charAt(0).toUpperCase()+(sr.accountManagerDetails.lastName).slice(1)}`:"NA";
        sr.accountManagerNumber = sr.accountManagerDetails ? `${sr.accountManagerDetails.mobileNumber}`:"NA";
        sr.pCode = sr.consumerDetails && sr.consumerDetails.address.pinCode ? sr.consumerDetails.address.pinCode  : "NA" ;
        sr.orderCount =  sr.ordersOnXirify ? sr.ordersOnXirify : "NA";
        // sr.SRAmount = sr.amount ? sr.amount.toFixed(2) : "Not Set";
        sr.SRAmount =  sr.amount && sr.couponAmount ? calculateSubtraction(sr.amount,sr.couponAmount) : sr.amount ? sr.amount.toFixed(2) : "Not set";
        let serviceModeUI ="";
        if(!sr.serviceMode){
            if( sr.preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION"){
                serviceModeUI = "Business Location" 
            } else if(sr.preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION"){
                serviceModeUI = "My Location" 
            } else {
                serviceModeUI = sr.preferredServiceMode
            }
        }

        sr.service = (!sr.deliveryType && sr.serviceMode && sr.preferredServiceMode) ? 
        sr.serviceMode :
         ( sr.serviceMode && sr.deliveryType) ? 
        getServiceMode(sr.serviceMode,sr.deliveryType) :
         (sr.preferredServiceMode &&  sr.serviceMode  && sr.deliveryType ) ? 
         getServiceMode(sr.serviceMode,sr.deliveryType)
          : (!sr.serviceMode) ? serviceModeUI  : "NA" ;
        sr.srCStatus = (typeof (sr.SRCouponStatus) === 'undefined' ? " " : (sr.SRCouponStatus && sr.SRCouponStatus === true ? "Active" : "Inactive"));
        sr.cancelledBy = ((sr.statusHistory[sr.statusHistory.length-1].status === "CANCELLED" ||sr.statusHistory[sr.statusHistory.length-1].status === "REJECTED_BY_CONSUMER")  && sr.statusHistory[sr.statusHistory.length-1].updatedBy.userType)  ? ((sr.statusHistory[sr.statusHistory.length-1].updatedBy.userType === "serviceprovider" ? "Business" : (sr.statusHistory[sr.statusHistory.length-1].updatedBy.userType === "consumer" ? "Shopping" : (sr.statusHistory[sr.statusHistory.length-1].updatedBy.userType === "servicePartner" ? "PetPooja" : "Admin")) )  ) : "NA" ;
    });
    let itemReportData = props.itemReportToExcel;
    itemReportData && itemReportData.forEach(sr=>{
        sr.creationDate = moment(sr.creationDate).format('DD MMM YYYY hh:mm A');
        sr.consumerName = sr.consumerName;
        sr.consumerId = sr.consumerId;
        sr.serviceMode = sr.serviceMode;
        sr.service = sr.service;
    });
    
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.total - props.page * props.rowsPerPage);
    const returnColor = (serviceRequest)=>{
        let color = "#2AB3F7" //customer preffered time
        if(serviceRequest.isPreOrder){
            color = "#C7709B" // preorder
        }else if(serviceRequest.isASAPOrder){
            color = "#000000" // asap order
        }
        if(serviceRequest.isSheduleDeliveyLater){
            color = "#70C87E" //schedule later
        }
        if(serviceRequest.rescheduledServiceDate){
            color = "#F8B051" //Business rescheduled time
        }
        return color
    }
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    total={props.data.total}
                    totalAmount={props.data.totalAmount}
                    headers={headers}
                    headersItemReport={headersItemReport}
                    data={data}
                    itemReportData={itemReportData}
                    showDownloadIcon={props.showDownloadIcon}
                    showDownloadIconForItemReport={props.showDownloadIconForItemReport}
                    fetchOrdersByQueryExcelFile={props.fetchOrdersByQueryExcelFile}
                    fetchOrdersByQueryItemReportExcelFile={props.fetchOrdersByQueryItemReportExcelFile}
                    isPreServiceRequst = {props.isPreServiceRequst}

                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.total}
                        />
                        <TableBody>
                            {stableSort(props.data.data, getComparator(props.order, props.orderBy))
                                // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                                .map((row, index) => {
                                    // console.log("row", index, row)
                                    // console.log("1",row.isASAPOrder === true);
                                    // console.log("2",(["ACCEPTED", "IN_PROGRESS", "OUT_FOR_DELIVERY", "DELIVERY_DONE", "COMPLETED"].includes(row.currentStatus)));
                                    // console.log("3",(row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY'));
                                    // console.log("4",(row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || 
                                    // row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax'));
                                    // console.log("5",((row.orderReadyTime < currentTime) || (!row.orderReadyTime && row.preparationTimer === null) ||(row.orderReadyTime === undefined && row.preparationTimer == 0) ));
                                    // console.log("6",(!row.partnerTaskId &&  (row.deliveryType !== 'xirify_delivery')) || row.isCancelDeliveryPartnerTask)
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let actionTime = row.statusHistory.filter((history) => {
                                        return history.status === row.currentStatus;
                                    })[0];

                                    let requestDetails = row.services && row.services.length > 0 ? <div>
                                        {/* <span style={{ display: "block" }}>Contact Person: {row.services[0].contactName}</span> */}
                                        {/* <span style={{ display: "block" }}>Contact: {row.services[0].contactPersonNumber}</span> */}

                                        <span style={{ display: "block" }}>Contact Person: {`${row.consumerDetails.firstName } ${row.consumerDetails.lastName} `}</span>
                                        <span style={{ display: "block" }}>Contact: {row.consumerDetails.primaryMobileNumber}</span>
                                    </div> : "";

                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.serviceRequestId}
                                            onClick={() => redirectToDetails(row)}
                                        >
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}> <Avatar className={classes.purple}>{(row.conversations) ? row.conversations.length:"0"}</Avatar></TableCell>
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>{row.serviceRequestId}</TableCell>
                                            {row.isAdvancePaymentToBusiness && !row.isAdvancePaymentDoneToBusiness && row.paymentStatus == "ADVANCEPAID" ? <TableCell style={{ color: returnColor(row), paddingBottom : "0px"}} align="left" className={classes.tableCellSmall}><div style={{ display: "flex", flexDirection: "row" }}> <Typography style={{ fontSize: "12px", paddingTop : "4px" }}> {getPaymentStatusInitial(row.paymentStatus)}</Typography>  <Tooltip style={{ width: "20px" , paddingLeft: "2px", marginTop : "-4px", paddingBottom : "2px" }} title="Advance Payment to business" >
                                             <Advance />
                                            </Tooltip> </div>  </TableCell> : row.isAdvancePaymentToBusiness && row.isAdvancePaymentDoneToBusiness ? <TableCell style={{ color: returnColor(row),paddingBottom : "0px" }} align="left" className={classes.tableCellSmall}><div style={{ display: "flex", flexDirection: "row" }}> <Typography style={{ fontSize: "12px" }}> {getPaymentStatusInitial(row.paymentStatus)}</Typography>  <Tooltip style={{ width: "20px" , paddingLeft: "2px", marginTop : "-4px", paddingBottom : "2px"}} title="Advance Payment to business" >
                                            <Green_AP/>
                                            </Tooltip> </div>  </TableCell>  : <TableCell style={{ color: returnColor(row) }} align="left" className={classes.tableCellSmall}>{getPaymentStatusInitial(row.paymentStatus)}</TableCell>}
                                            
                                            <TableCell style={{color:returnColor(row)}} component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                <Tooltip title={requestDetails} placement="top" arrow>
                                                    {/* <span>{row.consumerData[0].firstName + " " + row.consumerData[0].lastName}</span> */}
                                                    <span>{row.consumerDetails ? ((row.consumerDetails.firstName || row.consumerDetails.lastName ? row.consumerDetails.firstName + " " + row.consumerDetails.lastName : "--") ) : "--"}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{color:returnColor(row), width:"220px"}} align="left" className={classes.tableCellSmall}>{row?.ordersOnXirify||"NA"}</TableCell>
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>{moment(row.creationDate).format('DD MMM YYYY hh:mm A')}</TableCell>
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>{row.services && row.services !== null && row.services !== undefined ? row.services.length > 0 ? row.services[0].cost.costType : "Quick Request" : "NA"}</TableCell>
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>
                                            {/* {
                                            (row && (row.couponId.length>0) && row.couponId[0].amount && (row.couponId[0].status == 'Consumed' || row.couponId[0].status == 'PayBackSeller') && row.amount && (row.amount != null || row.amount != 'Not Set' || row.amount != undefined) && (row.amount > row.couponId[0].minOrderValue) ) ?
                                           calculateSubtraction(row.amount,row.couponId[0].amount)
                                            : 
                                            (row.amount ? row.amount.toFixed(2) : "Not Set")
                                            } */}
                                             {/* {row.amount ? row.amount.toFixed(2) : "Not set"} */}
                                                {row.amount && row.couponAmount ? calculateSubtraction(row.amount,row.couponAmount) : row.amount ? row.amount.toFixed(2) : "Not set"}
                                            </TableCell>
                                            <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>
                                                <span style={{ display: "block" }}>{row.currentStatus}</span>
                                                <span>{(actionTime && actionTime.time && actionTime.time !== undefined) ? moment(actionTime.time).format('DD MMM YYYY hh:mm A') : ""}</span></TableCell>
                                            {/* <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>{row.businessId?row.businessId:"--"}</TableCell> */}

                                            <TableCell style={{color:returnColor(row),paddingTop:"0px"}} align="left" className={classes.tableCellSmall}>
                                                { 
                                                    (row.isASAPOrder === true )  ? 
                                                        (
                                                            (["ACCEPTED", "IN_PROGRESS", "OUT_FOR_DELIVERY", "DELIVERY_DONE", "COMPLETED"].includes(row.currentStatus)) && 
                                                            (
                                                                ((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax'))
                                                            ) && 
                                                            (((row.orderReadyTime < currentTime) || (!row.orderReadyTime && row.preparationTimer === null) || (row.orderReadyTime === undefined && row.preparationTimer === 0) ) && 
                                                            (!row.partnerTaskId &&  (row.deliveryType !== 'xirify_delivery')) || row.isCancelDeliveryPartnerTask)
                                                        ) ? 
                                                            <span style={{paddingTop:"0px"}} className={classes.preparationTimer}>
                                                                <span className={classes.tableCellSmall} date = {row.orderReadyTime}>
                                                                    {
                                                                        (row.isASAPOrder === true) && 
                                                                        (row.currentStatus === 'ACCEPTED') && 
                                                                        (
                                                                            ((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax')) || row.serviceMode === 'PICKUP'
                                                                        )  &&  
                                                                        (row.orderReadyTime > currentTime) ? 
                                                                            <CountdownTimer date = {row.orderReadyTime}/> 
                                                                        : ""
                                                                    } 
                                                                </span>

                                                                <span style={{ display: "grid",padding:"4px"}} align="left" className={classes.tableCellSmall}>
                                                                    {
                                                                        (!row.deliveryType && row.serviceMode && row.preferredServiceMode) 
                                                                        ? row.serviceMode 
                                                                        : 
                                                                            (row.serviceMode && row.deliveryType) ? 
                                                                                getServiceMode(row.serviceMode,row.deliveryType) : 
                                                                                (row.preferredServiceMode &&  row.serviceMode  && row.deliveryType ) ?
                                                                                    getServiceMode(row.serviceMode,row.deliveryType) : (!row.serviceMode) ? 
                                                                                    row.preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION" ? "My Location"
                                                                                    : row.preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION" ? "Business Location" : row.preferredServiceMode  
                                                                                    : "--" 
                                                                    }
                                                                </span>
                                                                <span style={{width:"auto"}}>
                                                                    {
                                                                        row.deliveryType !== null && row.deliveryType !== undefined && row.deliveryType !=="" && row.deliveryType==="xirify_delivery" 
                                                                            ? <div style={{ padding: "10px" }} size="40px"><ByXirifySVG/></div>
                                                                            : null
                                                                    }
                                                                </span>
                                                            </span>
                                                        :
                                                            <span  style={{paddingTop:"0px"}} className={classes.tableCellSmall}>
                                                                <span className={classes.tableCellSmall} date = {row.orderReadyTime}>
                                                                    {
                                                                        (row.isASAPOrder === true) && 
                                                                        (row.currentStatus === 'ACCEPTED') && 
                                                                        (
                                                                            ((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax')) || row.serviceMode === 'PICKUP'
                                                                        )  && 
                                                                        (row.orderReadyTime > currentTime) ? 
                                                                            <CountdownTimer date = {row.orderReadyTime}/> 
                                                                        : ""
                                                                    } 
                                                                </span>
                                                                <span style={{ display: "grid",padding:"4px"}} align="left" className={classes.tableCellSmall}>
                                                                    {
                                                                        (!row.deliveryType && row.serviceMode && row.preferredServiceMode) 
                                                                            ? 
                                                                                row.serviceMode 
                                                                            : 
                                                                                (row.serviceMode && row.deliveryType) 
                                                                                ? getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                : 
                                                                                    (row.preferredServiceMode &&  row.serviceMode  && row.deliveryType ) 
                                                                                    ?
                                                                                        getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                    : 
                                                                                        (!row.serviceMode) ? row.preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION" ? "My Location"
                                                                                        : row.preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION" ? "Business Location" : row.preferredServiceMode  : "--" 
                                                                    }
                                                                </span>
                                                                <span style={{width:"auto"}}>
                                                                    {
                                                                        row.deliveryType !== null && row.deliveryType !== undefined && row.deliveryType !=="" && row.deliveryType==="xirify_delivery" 
                                                                            ?
                                                                                <div style={{ padding: "10px" }} size="40px"><ByXirifySVG/></div>
                                                                            : null
                                                                    }
                                                                </span>
                                                            </span>
                                                    : 
                                                        (
                                                            (["ACCEPTED", "IN_PROGRESS", "OUT_FOR_DELIVERY", "DELIVERY_DONE", "COMPLETED"].includes(row.currentStatus)) && 
                                                            (((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax'))) && 
                                                            (((row.orderReadyTime < currentTime) || (!row.orderReadyTime && row.preparationTimer === null) ) && 
                                                            (!row.partnerTaskId &&  (row.deliveryType !== 'xirify_delivery')) || row.isCancelDeliveryPartnerTask)
                                                        ) ? 
                                                            <span style={{paddingTop:"0px"}} className={classes.preparationTimer}>
                                                                <span className={classes.tableCellSmall} date = {row.orderReadyTime}>
                                                                    {
                                                                        (row.isASAPOrder === false) && 
                                                                        (row.currentStatus === 'ACCEPTED') && 
                                                                        (
                                                                            ((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax')) || row.serviceMode === 'PICKUP'
                                                                        )  &&  (row.orderReadyTime > currentTime) 
                                                                            ? 
                                                                                "" 
                                                                            : ""
                                                                    } 
                                                                </span>
                                                                <span style={{ display: "grid",padding:"4px"}} align="left" className={classes.tableCellSmall}>
                                                                    {
                                                                        (!row.deliveryType && row.serviceMode && row.preferredServiceMode) 
                                                                            ? row.serviceMode 
                                                                            : 
                                                                                (row.serviceMode && row.deliveryType) 
                                                                                    ? getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                    : 
                                                                                        (row.preferredServiceMode &&  row.serviceMode  && row.deliveryType ) 
                                                                                        ?
                                                                                            getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                        : 
                                                                                            (!row.serviceMode) 
                                                                                                ? row.preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION" ? "My Location"
                                                                                                : row.preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION" ? "Business Location" : row.preferredServiceMode  
                                                                                                : "--" 
                                                                    }
                                                                </span>
                                                                <span style={{width:"auto"}}>
                                                                    {
                                                                        row.deliveryType !== null && 
                                                                        row.deliveryType !== undefined && 
                                                                        row.deliveryType !=="" && 
                                                                        row.deliveryType==="xirify_delivery" 
                                                                            ? <div style={{ padding: "10px" }} size="40px"><ByXirifySVG/></div>
                                                                            : null
                                                                    }
                                                                </span>
                                                            </span>
                                                        :
                                                        <span  style={{paddingTop:"0px"}} className={classes.tableCellSmall}>
                                                            <span className={classes.tableCellSmall} date = {row.orderReadyTime}>
                                                                {
                                                                    (row.isASAPOrder === false) && 
                                                                    (row.currentStatus === 'ACCEPTED') && 
                                                                    (((row.serviceMode === 'DELIVERY' || row.preferredServiceMode === 'DELIVERY') && (row.deliveryType === 'dunzo' || row.deliveryType === 'borzo' || row.deliveryType === 'xirify_delivery' || row.deliveryType === 'porter' || row.deliveryType === 'shadowfax')) || row.serviceMode === 'PICKUP') && 
                                                                    (row.orderReadyTime > currentTime) ? "" : ""
                                                                } 
                                                            </span>

                                                            <span style={{ display: "grid",padding:"4px"}} align="left" className={classes.tableCellSmall}>
                                                                {
                                                                    (!row.deliveryType && row.serviceMode && row.preferredServiceMode) 
                                                                        ? row.serviceMode 
                                                                        : 
                                                                            (row.serviceMode && row.deliveryType) 
                                                                                ? getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                : 
                                                                                    (row.preferredServiceMode &&  row.serviceMode  && row.deliveryType ) 
                                                                                    ?
                                                                                        getServiceMode(row.serviceMode,row.deliveryType) 
                                                                                        : 
                                                                                            (!row.serviceMode) 
                                                                                                ? row.preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION" ? "My Location"
                                                                                                : row.preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION" ? "Business Location" : row.preferredServiceMode 
                                                                                                : "--" 
                                                                }
                                                            </span>
                                                            <span style={{width:"auto"}}>
                                                                {
                                                                    row.deliveryType !== null && row.deliveryType !== undefined && row.deliveryType !=="" && row.deliveryType==="xirify_delivery" 
                                                                        ? <div style={{ padding: "10px" }} size="40px"><ByXirifySVG/></div>
                                                                        : null
                                                                }
                                                            </span>
                                                </span>
                                                }
                                            </TableCell>

                                                {/* <TableCell style={{color:returnColor(row)}} align="left" className={classes.tableCellSmall}>{row.businessName?row.businessName:"--"} */}
                                            <TableCell>
                                            <span style={{color:returnColor(row)}}>{row.businessName? row.businessName:"--"}<br></br></span>
                                            <span style={{color:returnColor(row)}}>{row.businessId?   '(' + row.businessId +')':"--"}</span>
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableCellSmall}>{row.paymentStatus}</TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    // rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}