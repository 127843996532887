import React, { Component, Fragment } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import { getBusinessListApproved,getRegionsRecords, searchApprovedBusiness, updateBusiness, getBusinessExportToExcel, updateExotelForBusinesses, requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,} from "../../../actions/businessActions";
import { getBusinessListApproved,getRegionsRecords,getUsersRecords, searchApprovedBusiness, updateBusiness, getBusinessExportToExcel, updateExotelForBusinesses, requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,updateBusinessWithArea,clearMsg,getConsumerCount, requestBusinessDetailsRecords, requestBusinessDetailsRecordsFalse,addApprovedBusinessTag} from "../../../actions/businessActions";
import {getAllRegions} from "../../../actions/regionActions"
import { changeHeaderName, showApprovedBusinessDetails } from "../../../actions/dashboardActions";
import {getCategoriesByType} from "../../../actions/categoriesActions";
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import {updateDeliveryPartnerAvailability,updateBusinessStatus} from "../../../actions/businessActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import AssignAreaForm from '../PendingBusiness/AssignAreaForm';
import { getAreasByRegions } from "../../../actions/areaActions";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { ReactComponent as LINK } from '../../../images/Group 33893.svg';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getBusinessTagList} from "../../../actions/businessTagAction";


const styles = theme => ({
  content: {
    // width: '100%',
    // height: '100%',

    flexGrow: 1,
		height: '100%',
		overflow: 'auto',
  },
  backdrop: {
		zIndex: 2002,
		color: 'black',
   
	},
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '98%',
    height: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container2: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 750,
  },
  searchPaper: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
  },
  clearButton: {
    marginLeft: '25px'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableCellSmall: {
    fontSize: "13px",
    padding: "6px 0px 6px 16px"
  },
  unclickableTableCellSmall: {
    // fontSize: "12px",
    padding: "6px 0px 6px 20px"
},
  unclickableTableCellSmall1: {
    // fontSize: "12px",
    padding: "6px 6px 6px 20px"
},
box: {
  width: "50%",
  display: "inherit",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fafafa",
  borderRadius: "8px",
  justifyContent: 'space-around',
  boxShadow: "0px 1px 1px -1px"
},
assignAreaModalContainer: {
  width: '40%',
  height: '95%',
  backgroundColor: theme.palette.background.paper,
  padding: '0px 0px',
},
button: {
  // width: "110px",
  borderRadius:"30px",
  fontSize:"12px",
  marginRight:'10px',
  height:"30px",
  marginLeft:'5px',
  marginTop:'5px'
},

 businessTagsContainer:{
  display:'flex',
  width:'max-content',
  maxWidth:'380px',
  flexWrap:'wrap'
 },
 chip: {
  margin: theme.spacing(0.5),
  borderRadius: '20px',
  backgroundColor: '#F5F5F5'
},
modalBusinessTag: {
  display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   paddingLeft:'400px',
   paddingRight:'400px',
   position:'fixed',
   
},
headerBar: {
color: "white",
backgroundColor: theme.palette.primary.main,
},
headGridTwoButton: {
float: "right",
marginTop: "2px",
},
form: {
margin: "5% 10%",
backgroundColor: theme.palette.background.paper,
},  
dialogcontent: {
  margin: "10px 0px",
  overflow:'visible'

},
footer: {
height: "5%",
margin: "10px 0px 10px 0px",
display: "flex",
flexDirection:"row",
alignItems: "center",
justifyContent: "space-around",
},

});

const days = [ 'Monday' , 'Tuesday' , 'Wednesday' , 'Thursday' , 'Friday' , 'Saturday' , 'Sunday']
const paymentFeaturesValues = ["Pre-Payment", "Post-Payment",  "Post-Payment For Loyal Shoppers"]
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const 
MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 260,
        },
  },
  getContentAnchorEl: null,
};

function convertTime12to24(time12h) {
  let convertedTime = moment(time12h, 'hh:mm A').format('HH:mm');
  // console.log(convertedTime)
  return convertedTime;
};

function timeCheck(from, to) {
  let a = from;
  let b = to;
  let fromCheck = a.split(":");
  let toCheck = b.split(":");
  let f = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(fromCheck[0], 10), parseInt(fromCheck[1], 10));
  let t = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(toCheck[0], 10), parseInt(toCheck[1], 10));
  let df = f.valueOf();
  let dt = t.valueOf();

  if (df < dt)
    return false;
  // {console.log("b is greater");}
  else
    return true;
  // console.log("a is greater");
};

function uD(regions){
  var myVar4 = regions.join(',');

  let payload ={
    regionList:myVar4,
    businessStatus:"APPROVED"
  }
  return payload
  // this.props.getUsersRecords(payload)
}

function usersData(regions,users,webRole){
  // console.log("....regions",regions);
  // console.log("....users",users);
  // console.log("....webrole",webRole);
  let selectedUsers = [];
let selectedAccountM=[];
if(webRole.role === "regionalHead" || webRole.role === "admin"){
  regions.length >0 && regions.map((r)=>{
    users.length>0 &&  users.map((u)=>{
      u.businessDocId.length>0 && u.businessDocId !=null && u.businessDocId.map((busDocId)=>{
        if(busDocId.regionBelongsTo == r){
          selectedUsers.push(u)
        }
      })
  
      })
    })
}else if(webRole.role === "areaManager"){
  // console.log("inside areamanager",users)
  users.length>0 &&  users.map((u)=>{
   if( u.businessDocId.length>0 && u.businessDocId !=null){
    selectedUsers.push(u)
   }
  })

}

  selectedAccountM = Array.from(new Set(selectedUsers));
  // console.log("selectedAccountM...",selectedAccountM);
  return selectedAccountM
}
   
  


class ApprovedBusiness extends Component {

  constructor() {
    super();
    this.state = JSON.parse(window.localStorage.getItem('approvedBusinessDetails')) || {
      skip: 0,
      limit: 10,
      pageNumber: 0,
      value: '',
      clearSearch: false,
      businessFilter:"",
      ownerNameFilter:"",
      contactNumberFilter:"",
      categoryFilter:[],
      contractEndDateFilter:"",
      FilterEndDate:"",
      FilterStartDate:"",
      FilterBusinessAgreementEndDate:"",
      FilterBusinessAgreementStartDate:"",
      timeDateRange:"",
      businessAgreementTimeDateRange:"",
      xirifyAssuredFilter:"",
      // xirifyPromotedFilter:"",
      xirifyReferralFilter:"",
      preOrderFilter: "",
      sendQuoteFilter:"",
      businessStatusFilter:"",
      deliveryPartnerAvailabilityFilter:"",
      deliveryPartnerSelected:"",
      exotelSelected:"",
      petPoojaFilter:"",
      packagingChargesFilter:"",
      cashfreeEnabled : "",
      PGEnabled: "",
      ItemPackagingCharges:"",
      typeOrderFilter : "",
      ranges: { },
      showDownloadIcon:false,
     headers : [
        { key: 'businessName', label: 'Business Name' },        
        { key: 'businessId', label: 'Business Id' },
        { key: 'ownerFirstName', label: 'Owner First Name' },
        { key: 'ownerLastName', label: 'Owner Last Name' },
        { key: 'contactNumber', label: 'Contact Number' },
        { key: 'category', label: 'Category' },
        { key: 'activeContract.endDate', label: 'Contract End Date' },
        { key: 'pendingAmount', label: 'Contract Pending Amount' },
        { key: 'businessAgreementEndDate', label: 'Contract End Date (New)' },
        { key: 'status', label: 'Business Status' },
        { key: 'isPreOrder', label: 'Pre-Order Status' }, 
        {key:'isSendQuote', label:'Send Quote'},
        { key: 'resumeBusinessTime', label: 'Business Resume Time' }, 
        { key: 'xirifyAssured', label: 'Xirify Assured' },
        { key: 'xirifyReferral', label: 'Xirify Referral' },
        { key: 'deliveryPartnerAvailability', label: 'Delivery Partner Available' },
        { key: 'deliveryPartnerSelected', label: 'Delivery Partner Selected' },
        { key: 'address[1]', label: 'Latitude' }, 
        { key: 'address[0]', label: 'Longitude' },         
        { key: 'isExotel', label: 'Exotel Status'},
        { key: 'paymentFeature', label: 'Payment Features' },
        { key: 'isPetpoojaEnabled', label: 'Petpooja Status' },
        { key: 'isCashfreeEnabled', label: 'Cashfree Split Enabled' },
        { key: 'isPackagingChargesEnabled', label: 'Business Packaging Charges Status' },
        { key: 'businessPackagingCharges', label: 'Business Packaging Charges' },
        { key: 'isPGEnabled', label: 'Online Payment Gateway' },
        { key: 'isSerivicePackagingChargesAvailable', label: 'Item Packaging Charges' },
        {key : 'overallRating', label : 'Rating'},
        { key: 'isTypeOrderEnabled', label: 'Type-Order Status' },
        {key : 'reviews' , label : 'Reviews'},
        {key: 'uniqueReviewsCount' , label : 'Unique Reviews'},         
        {key: 'area' , label : 'Area'} , 
        {key : 'region' , label : 'Region'},
        {key : 'accountManagerDetails' , label : 'Account Manager'},
        { key: 'sortOrder', label: 'Sort Order' }, 
        { key: 'BusinessTags', label: 'Business Tags' },
      ] ,        

      
      from: [
        { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
        { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
        { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
        { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
        { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
        { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
        { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
        { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
        { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
        { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
        { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
        { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
      ],
      to: [
        { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
        { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
        { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
        { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
        { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
        { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
        { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
        { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
        { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
        { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
        { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
        { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
        { index: 48, from: "11:59 pm" }
      ],
      fromTiming: "",
      toTiming: "",
      invalidTime: "",
      invalidTimeFlag: false,
      openCloseDay : [],
      regionFilter:[],
      accountManagerFilter:"",
      accountManagerFilterError:false,
      areaRoleCheck:false,
      consumerModalOpen: false,
      currentConsumerCount: null,
      businessRadius: null,
      currentBusObj: {},
      calculatedConsumerCountModalOpen: false,
      businessName:"",
      isBusinessTagModalOpen:false,
      selectedBusinessId:"",
      selectedBusinessTags:[],
      businessTags:[],
      filteredBusinessTags:[],
      businessNoteSaveFlag:false,
      paymentFeatures : []

    };
  }

  // async componentDidMount() {
  async componentDidMount() {
    this.props.changeHeaderName("Approved Business");
    let areaRole = this.props.user.role
   
    // console.log(".....areaRole",areaRole);
    if(areaRole == "areaManager"){
      this.setState({areaRoleCheck:true})
      let payload ={
        businessStatus:"APPROVED"
      }
      // console.log("....payload",payload);
      await this.props.getUsersRecords(payload)
    }else {
      await this.props.getRegionsRecords();
      this.setState({areaRoleCheck:false})
    }
    // console.log("....this.state.areaROleCheck",this.state.areaRoleCheck);
 
  // await  this.props.getBusinessListApproved(this.state.skip, this.state.limit);
    // await this.fetchRecords();
   await this.fetchRecords();
   await this.props.getCategoriesByType("category");
   this.fetchBusinessTags();
    // this.props.getAllAreaManagers();
    window.addEventListener("beforeunload", () => localStorage.removeItem('approvedBusinessDetails'));
  }

  handleListItemClick = (busObj) => {
    this.props.showApprovedBusinessDetails(busObj);
    this.props.history.push("/ApprovedBusinessDetails");
  }

  handleChangePage = async (event, newPage) => {
      var skip;
      await this.setState({ pageNumber: newPage });
      skip = this.state.limit * newPage;
      this.setState({ skip: skip });
      await this.fetchRecords();
  };
  fetchRecords=async()=>{
     let hasError = false;
    // let convertedFrom;
    // let convertedTo;
    // let invalidToTimeCheck;
    // let invalidTime;
    // let invalidTimeFlag;

    // if(this.state.fromTiming && this.state.toTiming){
    //   // console.log("inside if condition");
    //   convertedFrom = convertTime12to24(this.state.fromTiming);
    //   convertedTo = convertTime12to24(this.state.toTiming);
    //   invalidToTimeCheck = timeCheck(convertedFrom, convertedTo); //whenevr a is greater, show to time is invalid value
    //   if (invalidToTimeCheck) { hasError = true; invalidTime = this.state.invalidTime; invalidTimeFlag = true; this.setState({ invalidTime, invalidTimeFlag:invalidTimeFlag }); }
    // }

   
    if(!hasError){
      this.props.requestApprovedBusinessRecords();
      // var myVar2 = this.state.categoryFilter.join(', ');
      var myVar3 = this.state.openCloseDay.join(', ');
      var myVar4 = this.state.regionFilter.join(', ');
      let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
      let payload ={
        businessName:this.state.businessFilter,
        ownerName:this.state.ownerNameFilter,
        mobileNumber:this.state.contactNumberFilter,
        category:this.state.categoryFilter,
        openCloseDay :myVar3,
        region:myVar4,
        contractFilterEndDate :this.state.FilterEndDate,
        contractFilterStartDate : this.state.FilterStartDate,
        businessAgreementFilterEndDate :this.state.FilterBusinessAgreementEndDate,
        businessAgreementFilterStartDate : this.state.FilterBusinessAgreementStartDate,
        xirifyAssured:this.state.xirifyAssuredFilter,
        xirifyReferral:this.state.xirifyReferralFilter,
        preOrderFilter:this.state.preOrderFilter,
        sendQuoteFilter:this.state.sendQuoteFilter,
        status: this.state.businessStatusFilter,
        isDeliveryPartnerAllowed:this.state.deliveryPartnerAvailabilityFilter,
        isDeliveryByPartner:this.state.deliveryPartnerSelected,
        isExotel:this.state.exotelSelected,
        petPoojaFilter:this.state.petPoojaFilter,
        packagingChargesFilter:this.state.packagingChargesFilter,
        isCashfreeEnabled : this.state.cashfreeEnabled,
        isPGEnabled : this.state.PGEnabled,
        isSerivicePackagingChargesAvailable : this.state.ItemPackagingCharges,
        skip : this.state.limit * this.state.pageNumber,
        limit: this.state.limit,
        businessStatus:"APPROVED",
        fromTiming: this.state.fromTiming,
        toTiming: this.state.toTiming,
        accountManagerFilter:this.state.accountManagerFilter,
        typeOrderFilter : this.state.typeOrderFilter,
        filteredBusinessTags:filteredBusinessTagsVar,
        paymentFeatures : this.state.paymentFeatures
       
      }
      // console.log(payload,"payload1")
      await this.props.getBusinessListApproved(payload);
      // this.props.getBusinessListApproved(payload);
      this.setState({showDownloadIcon:false});
      this.props.requestApprovedBusinessRecordsFalse();
      localStorage.setItem("approvedBusinessDetails", JSON.stringify(this.state));
    }
  };

  fetchBusinessTags=async()=>{
    const response=await getBusinessTagList();
    if(response){
      this.setState({businessTags:response.data})
    }else{
      this.setState({businessTags:[]})
    }
    
}
  
  handleChangeRowsPerPage =async (event) => {

    this.setState({ pageNumber: 0 });
    this.setState({ limit: event.target.value });
    this.setState({ skip: 0 });
    await this.fetchRecords();
   // this.props.getBusinessListApproved(0, event.target.value);
  };

  handleSearchChange = (event) => {
    this.setState({ value: event.target.value });
  }

  handleXirifyAvailabilitySwitchChange = async (event, data) => {
    let availabilityStatus = "";

    if (event.target.checked === true) {
      availabilityStatus ="ACTIVE"
    }
    else {
     availabilityStatus = "INACTIVE"
    }

    let payload = {
      businessId: data._id,
      data: {
        availibilitystatus : availabilityStatus
      }
    }
    await this.props.updateBusinessStatus(payload);
    // let skip = this.state.limit * this.state.pageNumber;
    this.fetchRecords();
    //this.props.getBusinessListApproved(skip, this.state.limit);
  };

  handleXirifyAssuredSwitchChange = async (event, data) => {
    let payload = {
      businessId: data._id,
      data: {
        xirifyAssured: event.target.checked
      }
    }
    await this.props.updateBusiness(payload);
    // let skip = this.state.limit * this.state.pageNumber;
    await this.fetchRecords();
    // this.props.getBusinessListApproved(skip, this.state.limit);
  };
  handleXirifyPreOrderSwitchChange = async (event, data) => {
    let payload = {
      businessId: data._id,
      data: {
        isPreOrder: event.target.checked
      }
    }
    await this.props.updateBusiness(payload);
    await this.fetchRecords();
  };

  handleSendQuoteSwitchChange = async (event, data) => {
    let payload = {
      businessId: data._id,
      data: {
        isSendQuote: event.target.checked
      }
    }
    await this.props.updateBusiness(payload);
    await this.fetchRecords();
  };

  // handleXirifyPromotedSwitchChange = async (event, data) => {
  //   let payload = {
  //     businessId: data._id,
  //     data: {
  //       xirifyPromoted: event.target.checked
  //     }
  //   }
  //   await this.props.updateBusiness(payload);
  //   this.fetchRecords();
  //   // let skip = this.state.limit * this.state.pageNumber;
  //   // this.props.getBusinessListApproved(skip, this.state.limit);
  // };

  handleXirifyReferralSwitchChange = async (event, data) => {
    let payload = {
      businessId: data._id,
      data: {
        xirifyReferral: event.target.checked
      }
    }
    await this.props.updateBusiness(payload);
    this.fetchRecords();
  };
  handleExotelSwitchChange = async (event, data) => {
    let payload = {
      businessId: data._id,
      data: {
        isExotel: event.target.checked
      }
    }
    await this.props.updateExotelForBusinesses(payload);
    this.fetchRecords();
  };
 
  fetchBusinessWithFilter =async ()=>{
    let hasError = false;
    // let convertedFrom;
    // let convertedTo;
    // let invalidToTimeCheck;
    // let invalidTime;
    // let invalidTimeFlag;

    // if(this.state.fromTiming && this.state.toTiming){
    //   // console.log("inside if condition");
    //   convertedFrom = convertTime12to24(this.state.fromTiming);
    //   convertedTo = convertTime12to24(this.state.toTiming);
    //   invalidToTimeCheck = timeCheck(convertedFrom, convertedTo); //whenevr a is greater, show to time is invalid value
    //   if (invalidToTimeCheck) { hasError = true; invalidTime = this.state.invalidTime; invalidTimeFlag = true; this.setState({ invalidTime, invalidTimeFlag:invalidTimeFlag }); }
    // }
    if(this.state.regionFilter.length !=0){
      if(!this.state.accountManagerFilter){
        this.setState({accountManagerFilterError :true});
        hasError = true;
      }
   }
    if(!hasError){
      this.props.requestApprovedBusinessRecords();
      // var myVar2 = this.state.categoryFilter.join(', ');
      var myVar3 = this.state.openCloseDay.join(', ');
      var myVar4 = this.state.regionFilter.join(', ');
      let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
      let payload ={
        businessName:this.state.businessFilter.trim(),
        ownerName:this.state.ownerNameFilter.trim(),
        mobileNumber:this.state.contactNumberFilter.trim(),
        category:this.state.categoryFilter,
        openCloseDay :myVar3,
        region:myVar4,
        contractFilterEndDate :this.state.FilterEndDate,
        contractFilterStartDate : this.state.FilterStartDate,
        businessAgreementFilterEndDate :this.state.FilterBusinessAgreementEndDate,
        businessAgreementFilterStartDate : this.state.FilterBusinessAgreementStartDate,
        xirifyAssured:this.state.xirifyAssuredFilter,
        xirifyReferral:this.state.xirifyReferralFilter,
        preOrderFilter: this.state.preOrderFilter,
        sendQuoteFilter:this.state.sendQuoteFilter,
        status: this.state.businessStatusFilter,
        isDeliveryPartnerAllowed:this.state.deliveryPartnerAvailabilityFilter,
        isDeliveryByPartner:this.state.deliveryPartnerSelected,
        isExotel: this.state.exotelSelected,
        petPoojaFilter: this.state.petPoojaFilter,
        packagingChargesFilter: this.state.packagingChargesFilter,
        isCashfreeEnabled : this.state.cashfreeEnabled,
        isPGEnabled : this.state.PGEnabled,
        isSerivicePackagingChargesAvailable:this.state.ItemPackagingCharges,
        skip : 0,
        limit: 10,
        businessStatus:"APPROVED",
        fromTiming: this.state.fromTiming,
        toTiming: this.state.toTiming,
        accountManagerFilter:this.state.accountManagerFilter,
        typeOrderFilter: this.state.typeOrderFilter,
        filteredBusinessTags:filteredBusinessTagsVar,
        paymentFeatures : this.state.paymentFeatures

      }
      await this.props.searchApprovedBusiness(payload);
      this.setState({showDownloadIcon:false,limit:10,pageNumber:0});
      this.props.requestApprovedBusinessRecordsFalse();
      localStorage.setItem("approvedBusinessDetails", JSON.stringify(this.state));
    }
  }
  handleFilterChange = async (event) => {
    if(event.target.name == "accountManagerFilter"){
      let accountString = event.target.value
      this.setState({accountManagerFilterError :false,
      accountManagerFilter:accountString})
    }else if(event.target.name == "regionFilter"){
       await this.setState({
        regionFilter:event.target.value,
        accountManagerFilter:""
      })
      if(this.state.regionFilter.length >0){
        let user = uD(this.state.regionFilter)
      await this.props.getUsersRecords(user)
      }
    }
    else {
      this.setState({ [event.target.name]: event.target.value, invalidTimeFlag: false });
    }
    console.log("consumerStatusPayment ----->", this.state.paymentFeatures)
}
  resetFilter = async ()=>{
    this.props.requestApprovedBusinessRecords();
    this.setState({
      businessFilter:"",
      ownerNameFilter:"",
      contactNumberFilter:"",
      categoryFilter:[],
      contractEndDateFilter:"",
      FilterEndDate:"",
      FilterStartDate:"",
      FilterBusinessAgreementEndDate:"",
      FilterBusinessAgreementStartDate:"",
      timeDateRange:"",
      businessAgreementTimeDateRange:"",
      xirifyAssuredFilter:"",
      // xirifyPromotedFilter:"",
      xirifyReferralFilter:"",
      preOrderFilter: "",
      sendQuoteFilter:"",
      businessStatusFilter:"",
      deliveryPartnerAvailabilityFilter:"",
      exotelSelected:"",
      petPoojaFilter:"",
      packagingChargesFilter:"",
      deliveryPartnerSelected:"",
      cashfreeEnabled: "",
      PGEnabled: "",
      ItemPackagingCharges:"",
      limit:10,
      pageNumber:0,
      fromTiming: "",
      toTiming: "",
      invalidTimeFlag: false,
      openCloseDay : [],
      regionFilter:[],
      accountManagerFilter:"",
      accountManagerFilterError:false,
      typeOrderFilter:"",
      filteredBusinessTags:[],
      paymentFeatures : []
    },
    () => {
      this.fetchBusinessWithFilter();
    }
  ); 
  }
  handleDateRangeChange = (event, picker) => {

    let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
    this.setState({
        // FilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
        // FilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
        FilterStartDate: picker.startDate.format(),
        FilterEndDate: picker.endDate.format()
    });
    let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
    this.setState({ timeDateRange: chosenLabel });
}
handleBusinessAgreementDateRangeChange = (event, picker) => { 
    let reportTimeRangeBA = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
    this.setState({
        FilterBusinessAgreementEndDate:picker.endDate.format(),
        FilterBusinessAgreementStartDate: picker.startDate.format()
    });
    let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRangeBA;
    this.setState({ businessAgreementTimeDateRange: chosenLabel });
}
fetchExportToExcel=async()=>{
  this.props.requestApprovedBusinessRecords();
  // var myVar2 = this.state.categoryFilter.join(', ');
  var myVar3 = this.state.openCloseDay.join(', ');
  var myVar4 = this.state.regionFilter.join(', ');
  let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
  let payload ={
    businessName:this.state.businessFilter.trim(),
    ownerName:this.state.ownerNameFilter.trim(),
    mobileNumber:this.state.contactNumberFilter.trim(),
    category:this.state.categoryFilter,
    openCloseDay :myVar3,
    region:myVar4,
    contractFilterEndDate :this.state.FilterEndDate,
    contractFilterStartDate : this.state.FilterStartDate,
    businessAgreementFilterEndDate :this.state.FilterBusinessAgreementEndDate,
    businessAgreementFilterStartDate : this.state.FilterBusinessAgreementStartDate,
    xirifyAssured:this.state.xirifyAssuredFilter,
    xirifyReferral:this.state.xirifyReferralFilter,
    preOrderFilter: this.state.preOrderFilter,
    sendQuoteFilter:this.state.sendQuoteFilter,
    status: this.state.businessStatusFilter,
    isDeliveryPartnerAllowed:this.state.deliveryPartnerAvailabilityFilter,
    isDeliveryByPartner:this.state.deliveryPartnerSelected,
    skip : this.state.limit * this.state.pageNumber,
    limit: this.state.limit,
    isExotel: this.state.exotelSelected,
    petPoojaFilter:this.state.petPoojaFilter,
    packagingChargesFilter:this.state.packagingChargesFilter,
    isCashfreeEnabled : this.state.cashfreeEnabled,
    isPGEnabled : this.state.PGEnabled,
    isIPackagingCharges:this.state.isIPackagingCharges,
    isSerivicePackagingChargesAvailable:this.state.ItemPackagingCharges,
    businessStatus:"APPROVED",
    fromTiming: this.state.fromTiming,
    toTiming: this.state.toTiming,
    accountManagerFilter:this.state.accountManagerFilter,
    typeOrderFilter :this.state.typeOrderFilter,
    filteredBusinessTags:filteredBusinessTagsVar,
    paymentFeatures: this.state.paymentFeatures
    
  }
  await this.props.getBusinessExportToExcel(payload);
  this.setState({showDownloadIcon:true});
  this.props.requestApprovedBusinessRecordsFalse();
}
handleDeliveryPartnerAvailabilitySwitchChange = async (event, data) => {
  let payload = {
      businessId: data._id,
      data: {
          isDeliveryPartnerAllowed: event.target.checked
      }
  }
   await this.props.updateDeliveryPartnerAvailability(payload);
   this.fetchRecords();
};
handleUsersData = async (user) => {
 await this.props.getUsersRecords(uD(this.state.regionFilter))
}

assignAreaModalOpen = (business) => {
  this.props.getAreasByRegions({ regionids: business.regionBelongsTo._id });
  this.setState({
    assignAreaModalOpen: true,
    selectedBusiness: business
  });
}

assignAreaModalClose = () => {
  this.setState({
    assignAreaModalOpen: false
  })
}

assignArea = (props) => {
  this.props.updateBusinessWithArea(props);
  this.setState({
    assignAreaModalOpen: false,
    selectedBusiness: null
  });
}

handleSnackClose = () => {
  this.setState({ snackOpen: false });
  this.setState({ assignAreaModalOpen: false });
  this.setState({ sucessMsg: null });
  this.setState({ errorMsg: null });
  // this.fetchAreaManagersByQuery();
}

componentDidUpdate(prevProps, prevState) {
  if (this.props.business.sucessMsg) {
    this.setState({ sucessMsg: "Area Updated successfully." })
    this.setState({ snackOpen: true });
    this.setState({ assignAreaModalOpen: false });
    // this.props.getBusinessListAwaitingApproval(this.state.skip, this.state.limit);
    this.fetchBusinessWithFilter();
    this.props.clearMsg();
  } else if (this.props.business.errorMsg) {
    this.setState({ errorMsg: this.props.business.errorMsg })
    this.setState({ snackOpen: true });
    this.props.clearMsg();
  }
}
getConsumerCount = async() => {
 this.props.requestApprovedBusinessRecords();
  this.state.currentBusObj["radius"] = this.state.businessRadius;
  let payload = this.state.currentBusObj

 
  await this.props.getConsumerCount(payload);
  this.setState({ calculatedConsumerCountModalOpen: true, currentConsumerCount: this.props.business.currentConsumerCount });
  this.props.requestApprovedBusinessRecordsFalse();

}
openConsumerCountModalOpen = async (busObj)=> {
  // console.log("busObj ---->",busObj)  
   this.setState({ consumerModalOpen: true,
    businessName: busObj.businessName, 
    businessRadius: busObj.radius,
    businessId: busObj.businessId,
    currentBusObj : {
    businessId: busObj.businessId,
    longitude: busObj.address.location.coordinates[0],
    latitude: busObj.address.location.coordinates[1],
    currentConsumerCount: null,
    calculatedConsumerCountModalOpen: false,
    openConsumerCountModalOpen: false,
  
   }});
}
 closeConsumerCountModalOpen = () => {

  this.setState({ consumerModalOpen: false, currentConsumerCount: null, openConsumerCountModalOpen: false, calculatedConsumerCountModalOpen:null, businessRadius: null, currentBusObj: {}, businessName: "", businessId: "" });
}

handleChange = (event) => {      
       // console.log(".....inside amount...",this.state.amount);
       this.setState({businessRadius: event.target.value, calculatedConsumerCountModalOpen: false})
      
   
   }

   handleClickAddTag=(id,selectedBusinessTags)=>{
    this.setState({ isBusinessTagModalOpen:true});
    this.setState({ selectedBusinessId:id});
    if(selectedBusinessTags){
      const selectedBusinessTagsNames=selectedBusinessTags.map((eachSelectedBusinessTag)=>eachSelectedBusinessTag.name);
      this.setState({ selectedBusinessTags:selectedBusinessTagsNames});
    }
   
    
  }

  handleBusinessTagModalClose=()=>{
    this.setState({ isBusinessTagModalOpen:false});
        this.setState({ selectedBusinessTags:[]});

  }
  handleSelectedTags=(e)=>{
    this.setState({ selectedBusinessTags:e.target.value});
  }
  handleAddTagSubmit=async (id)=>{
    let finalSelectedBusinessTag=this.state.businessTags.filter((eachBusinessTag)=>this.state.selectedBusinessTags.includes(eachBusinessTag.name));
    if(finalSelectedBusinessTag){
      let finalSelectedBusinessTagIds=finalSelectedBusinessTag.map((eachfinalselectedBusinessTag)=>eachfinalselectedBusinessTag._id);
      this.props.addApprovedBusinessTag(id,finalSelectedBusinessTagIds)
      this.setState({ isBusinessTagModalOpen:false});
    }
    
  }
  handleDeleteBusinessTag=(businessId,id,businessTags)=>{
    let finalSelectedBusinessTag=businessTags.filter((eachBusinessTag)=>eachBusinessTag._id!==id);
            let finalSelectedBusinessTagIds=finalSelectedBusinessTag.map((eachfinalselectedBusinessTags)=>eachfinalselectedBusinessTags._id);
        this.props.addApprovedBusinessTag(businessId,finalSelectedBusinessTagIds)  
  }
  render() {
    const { classes } = this.props;
    let muiAlert = null;
    // console.log("....in approved business ...this.props...",this.props)
    let data = (this.props && this.props.business && this.props.business.businessExcelRecords) ? this.props.business.businessExcelRecords :[];
    data && data.forEach(element => {
      if(!element.activeContract){
        element.activeContract={}
      }
      if(element.activeContract && element.activeContract !== "NA" && element.activeContract.endDate && element.activeContract.endDate !== null && element.activeContract.endDate !== undefined && element.activeContract.endDate !== "NA") {

        element.activeContract.endDate =  moment(element.activeContract.endDate).format('DD MMM YYYY')
      } 
      else {
        element.activeContract={};
        element.activeContract.endDate =  "NA"
      }
      
      if(element.businessagreements && element.businessagreements.length > 0 && element.businessagreements[0].endDate && element.businessagreements[0].endDate !== null && element.businessagreements[0].endDate !== undefined) {
        element.businessAgreementEndDate = moment(element.businessagreements[0].endDate).format('DD MMM YYYY')
      } else {element.businessAgreementEndDate = "NA"}
      if(element?.businessTagIds?.length){
        const businessTagNames=element?.businessTagIds?.map(eachfilteredBusinessTagId =>
          this.state.businessTags?.find(eachBusinessTagObj => eachBusinessTagObj['_id'] === eachfilteredBusinessTagId)
        )
        .filter(match => match)
        .map(match => match.name);
        element.BusinessTags=businessTagNames.join();
      }else{
        element.BusinessTags="Not Assisgned";
      }
    });
    let dataRegion =[];
    dataRegion = this.props.business.regionsData;
    // let usersData= this.props.business.usersData;
    // console.log(".....this.state.regionFilter",this.state.regionFilter)
    // console.log("..this.props.business.usersData..",this.props.business.usersData)
    let usersDataforAM = usersData(this.state.regionFilter,this.props.business.usersData,this.props.user)
   
    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    }
    
    return (

      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Container className={classes.searchPaper}>
          <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Search Business</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                <Grid container spacing={1}>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Business Id / Name
                    </Typography>
                    <TextField fullWidth margin="dense" name="businessFilter" style={{ width: "225px" }} value={this.state.businessFilter} variant="outlined" onChange={this.handleFilterChange} />
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Owner Name
                    </Typography>
                    <TextField fullWidth margin="dense" name="ownerNameFilter" style={{ width: "225px" }} value={this.state.ownerNameFilter} variant="outlined" onChange={this.handleFilterChange} />
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Contact Number
                    </Typography>
                    <TextField fullWidth margin="dense" name="contactNumberFilter" style={{ width: "225px" }} value={this.state.contactNumberFilter} variant="outlined" onChange={this.handleFilterChange} />
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Category
                    </Typography>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel id="weeklyDays">Select Category</InputLabel>
                                            <Select
                                                labelId="weeklyDays"
                                                multiple
                                                value={this.state.categoryFilter}
                                                name="categoryFilter"
                                                onChange={this.handleFilterChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(',')}
                                                MenuProps={MenuProps}
                                            >
                                                {this.props.categories.map((category) => (
                                                    <MenuItem key={category._id} value={category.name} style={{ maxWidth: "100%", overflow:"hidden", wordWrap:"normal", whiteSpace:"normal" }}>
                                                        <Checkbox checked={this.state.categoryFilter.indexOf(category.name) > -1} />
                                                        <ListItemText primary={category.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                  </Grid>
                  <Grid item xs={3}  >
                  <Typography variant="subtitle2" gutterBottom>
                      Contract End Date
                    </Typography>
                    <DateRangePicker
                                        ranges={{
                                       'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]}}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="DateFilter"  value={this.state.timeDateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                  </Grid>
                  <Grid item xs={3}  >
                  <Typography variant="subtitle2" gutterBottom>
                      Contract End Date (New)
                    </Typography>
                    <DateRangePicker
                                        ranges={{
                                       'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]}}
                                        onApply={this.handleBusinessAgreementDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="DateFilter"  value={this.state.businessAgreementTimeDateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                  </Grid>
                  <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom> Business Open/Close Day</Typography>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                  <InputLabel id="demo-mutiple-checkbox-label">Select Day</InputLabel>
                   <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={this.state.openCloseDay}
                    name="openCloseDay"
                    onChange={this.handleFilterChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    >
                    {days.map((day) => (
                        <MenuItem key={day} value={day} style={{ minHeight: "28px", height: "28px" }}>
                            <Checkbox checked={this.state.openCloseDay.indexOf(day) > -1} />
                            <ListItemText primary={day} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
                   </Grid>        
                  
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom> Business OPEN Time </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.fromTiming}
                        name="fromTiming"
                        onChange={this.handleFilterChange}
                        // label="Business OPEN Time"
                        style={{ width: "225px" }}
                      >
                        {this.state.from.map((index) => {
                              return (
                                <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                </MenuItem>
                              )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom> Business CLOSE Time </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      {/* <InputLabel id="demo-simple-select-standard-label">Business CLOSE Time</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="to"
                        value={this.state.toTiming}
                        name="toTiming"
                        onChange={this.handleFilterChange}
                        // label="Business CLOSE Time"
                        style={{ width: "225px" }}
                       // error={this.state.invalidTimeFlag ? true : false}                                                               // removed the error for story 6118
                        // helperText={this.state.invalidTimeFlag == true ? "Close time should be greater than Open time" : null}
                      >
                        {this.state.to.map((index) => {
                              return (
                                <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                </MenuItem>
                              )
                        })}
                      </Select>
                    </FormControl>
                    {/* <Grid item xs={3}>  *************for giving error message ***********************
                    <FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text"> {this.state.invalidTimeFlag == true ? "Close time should be greater than Open time" : null} </FormHelperText>
                  </Grid> */}
                  </Grid>
                  {
                      !this.state.areaRoleCheck ?
                    
                  <div className={classes.box}>
                  <Grid item xs={5} >
                  <Typography variant="subtitle2" gutterBottom>
                      Region
                    </Typography>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel  id="Regions">Select Region</InputLabel>
                                            <Select
                                                labelId="Regions"
                                                multiple
                                                value={this.state.regionFilter}
                                                name="regionFilter"
                                                 onChange={this.handleFilterChange}
                                                 style ={{width: "225px"}}
                                                 onSelect={this.handleUsersData}                                       
                                                input={<Input />}
                                                renderValue={(selected) =>
                                                  {
                                                    let nameArray=[];
                                                      selected.map((m)=>{
                                                      dataRegion.map((d)=>{
                                                        if(d._id == m){
                                                          if(nameArray.length== 0)
                                                          nameArray.push(d.name);
                                                          else
                                                          nameArray.push( ", "+d.name)
                                                        }
                                                      })
                                                     
                                                    })
                                                      return nameArray}}
                                                MenuProps={MenuProps}
                                            >
                                                {dataRegion.map((region) => (
                                                    <MenuItem key={region._id} value={region._id} style={{ maxWidth: "100%", overflow:"hidden", 
                                                    wordWrap:"normal", whiteSpace:"normal" }}>
                                                        <Checkbox  checked={this.state.regionFilter.indexOf(region._id) > -1 } 
                                                        />
                                                        <ListItemText  primary={region.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            
                                        </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.accountManagerFilter}
                        name="accountManagerFilter"
                        onChange={this.handleFilterChange}
                        // label="Business OPEN Time"
                        style={{ width: "225px" }}
                      >
                        {usersDataforAM.map((user) => {
                              return (
                                <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap 
                               >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                                </MenuItem>
                              )
                        })}
                      </Select>
                      {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                      : null
                 }
                    </FormControl>
                  </Grid>

                  </div>
                  :
                  
                  <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="fronpm run devm"
                      value={this.state.accountManagerFilter}
                      name="accountManagerFilter"
                      onChange={this.handleFilterChange}
                      // label="Business OPEN Time"
                      style={{ width: "225px" }}
                    >
                      {usersDataforAM.map((user) => {
                            return (
                              <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                <Typography noWrap 
                             >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                              </MenuItem>
                            )
                      })}
                    </Select>
                    {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                    : null
               }
                  </FormControl>
                </Grid>
                }
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>Business Tags</Typography>
                          <FormControl variant="outlined" fullWidth className={classes.formControl} >
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={this.handleFilterChange}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {this.state. businessTags.map((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </Grid> 
                      <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>Payment Features</Typography>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} style = {{width: "225px"}} >
                  {/* <InputLabel id="demo-mutiple-checkbox-label">Select Day</InputLabel> */}
                   <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={this.state.paymentFeatures}
                    name="paymentFeatures"
                    onChange={this.handleFilterChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    
                    >
                    {paymentFeaturesValues.map((index) => (
                        <MenuItem key={index} value={index} style = {{ maxWidth: "100%", overflow:"hidden", 
                          wordWrap:"normal", whiteSpace:"normal" , height: "auto"}}>
                            <Checkbox checked={this.state.paymentFeatures.indexOf(index) > -1} style={{paddingLeft : "5px"}} />
                            <ListItemText primary={index} />
                        </MenuItem>
                    ))}
                </Select>
                 </FormControl>
                   </Grid>        
                  
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Xirify Assured
                    </Typography>
                    <RadioGroup row aria-label="position" name="xirifyAssuredFilter" onChange={this.handleFilterChange} value={this.state.xirifyAssuredFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Xirify Referral
                    </Typography>
                    <RadioGroup row aria-label="position" name="xirifyReferralFilter" onChange={this.handleFilterChange} value={this.state.xirifyReferralFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Business Status
                    </Typography>
                    <RadioGroup row aria-label="position" name="businessStatusFilter" onChange={this.handleFilterChange} value={this.state.businessStatusFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Open" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Open</Typography>} size="small" />
                      <FormControlLabel value="Closed" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Closed</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Pre-Order Status
                    </Typography>
                    <RadioGroup row aria-label="position" name="preOrderFilter" onChange={this.handleFilterChange} value={this.state.preOrderFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                       Send Quote
                    </Typography>
                    <RadioGroup row aria-label="position" name="sendQuoteFilter" onChange={this.handleFilterChange} value={this.state.sendQuoteFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                 
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Delivery Partner Availability
                    </Typography>
                    <RadioGroup row aria-label="position" name="deliveryPartnerAvailabilityFilter" onChange={this.handleFilterChange} value={this.state.deliveryPartnerAvailabilityFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="On" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>On</Typography>} size="small" />
                      <FormControlLabel value="Off" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Off</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Delivery Partner Selected
                    </Typography>
                    <RadioGroup row aria-label="position" name="deliveryPartnerSelected" onChange={this.handleFilterChange} value={this.state.deliveryPartnerSelected}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                      Exotel Selected
                    </Typography>
                    <RadioGroup row aria-label="position" name="exotelSelected" onChange={this.handleFilterChange} value={this.state.exotelSelected}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>
                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                      Petpooja Enabled
                    </Typography>
                    <RadioGroup row aria-label="position" name="petPoojaFilter" onChange={this.handleFilterChange} value={this.state.petPoojaFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>
                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                      Cashfree Split Enabled
                    </Typography>
                    <RadioGroup row aria-label="position" name="cashfreeEnabled" onChange={this.handleFilterChange} value={this.state.cashfreeEnabled}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>
                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                     Online Payment Gateway
                    </Typography>
                    <RadioGroup row aria-label="position" name="PGEnabled" onChange={this.handleFilterChange} value={this.state.PGEnabled}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>

                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                  Business Packaging Charges
                    </Typography>
                    <RadioGroup row aria-label="position" name="packagingChargesFilter" onChange={this.handleFilterChange} value={this.state.packagingChargesFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>

                  
                  <Grid item xs={3}>
                  {/* {this.props.user.role === "admin" && */}
                  <Fragment>
                  <Typography variant="subtitle2" gutterBottom>
                  Item Packaging Charges
                    </Typography>
                    <RadioGroup row aria-label="position" name="packagingChargesFilter" onChange={this.handleFilterChange} value={this.state.packagingChargesFilter}>
                   </RadioGroup>
                    <RadioGroup row aria-label="position" name="ItemPackagingCharges" onChange={this.handleFilterChange} value={this.state.ItemPackagingCharges}>
                      
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Fragment>
                    {/* // } */}
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Type-Order Status
                    </Typography>
                    <RadioGroup row aria-label="position" name="typeOrderFilter" onChange={this.handleFilterChange} value={this.state.typeOrderFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  {/* <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.accountManagerFilterError ?"Select Account Manager":null} </FormHelperText></Grid> */}
                  
                  <Grid item xs={6}></Grid>
                  <Grid item xs={3}><Typography>  </Typography></Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      margin="normal"
                     onClick={this.resetFilter}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      margin="normal"
                      color="primary"
                     onClick={this.fetchBusinessWithFilter}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Container>
                {this.state.showDownloadIcon ?
                    <CSVLink
                        style={{float:"right",margin:"10px"}}
                        data={this.props.business.businessExcelRecords}
                        headers={this.state.headers}
                        filename={"ApprovedBusiness.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" , float:"right" ,margin:"10px"}}
                        onClick={this.fetchExportToExcel}
                    >
                        Export
                    </Button>
                }
          <TableContainer component={Paper}>
            <Table className={classes.table}
              aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  {this.props.user.role === "areaManager" ? <TableCell align="left"></TableCell> : <TableCell></TableCell>}
                  <TableCell>Business Id</TableCell>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>Contact Number</TableCell>
                  {/* <TableCell>Category Type</TableCell> */}
                  <TableCell>Category</TableCell>
                  <TableCell>Contract Pending Amount</TableCell>
                  <TableCell>Contract End Date</TableCell>
                  <TableCell>Contract End Date (New)</TableCell>
                  <TableCell>Business Status</TableCell>
                  <TableCell>Pre-Order Status</TableCell>
                  <TableCell>Send Quote</TableCell>
                  <TableCell>Business Resume Time</TableCell>
                  <TableCell>Xirify Assured</TableCell>
                  {/* <TableCell>Xirify Promoted</TableCell> */}
                  <TableCell>Xirify Referral</TableCell>
                  <TableCell>Delivery Partner Availability</TableCell>
                  <TableCell>Delivery Partner Selected</TableCell>
                  {/* <TableCell>Latitude</TableCell>
                  <TableCell>Longitude</TableCell> */}
                  {/* {this.props.user.role === "admin"&&  */}
                  <TableCell>Exotel Status</TableCell>
                  <TableCell>Payment Features</TableCell>
                  {/* } */}
                  <TableCell>Type-Order Status</TableCell>
                  <TableCell>Ratings</TableCell>
                  <TableCell>Reviews</TableCell>
                  <TableCell>Unique Reviews</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Account Manager</TableCell>
                  <TableCell>Sort Order</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.business.busApprovedList.map((busObj, index) => {
                  //  console.log('from busObj',busObj)
                  let ownerName = busObj.ownerId ? busObj.ownerId.firstName + " " + busObj.ownerId.lastName : 'NOT AVAILABLE';
                  // let categoryTypeName = busObj.categoryType ? busObj.categoryType.name : 'NOT AVAILABLE';
                  let categoryName = busObj.category ? busObj.category.name : 'NOT AVAILABLE';
                  let   businessId = busObj._id;
                  // console.log('from businessId',businessId)
                  let areaName = this.props.business.busApprovedList.map((i)=>{
                    if(businessId.areaBelongsTo === i.belongsTo._id){
                      return(i.areaBelongsTo.name)
                    }
                    // console.log('from  i.areaBelongsTo', i.areaBelongsTo);
                      
                  }) 
                  // console.log('areaName',areaName)
                  let totalPaidAmount = 0;
                  let pendingAmount = 0;
                  let paymentStatus = 'NIL';
                  let activeContract = busObj.contracts && busObj.contracts.length === 0 ? null : busObj.contracts.filter(contract => { return contract.isActive })[0];
                  if (activeContract && activeContract !== null && activeContract !== undefined) {
                    activeContract.payments.forEach(payment => {
                      totalPaidAmount += Number(payment.amount);
                    });
                    pendingAmount = Number(activeContract.agreedAmount) - Number(totalPaidAmount);
                    paymentStatus = pendingAmount > 0 ? pendingAmount : 'NIL';
                  }
                  let sortOrderName;
                  if(busObj.sortOrder == 10){ sortOrderName = 'Assured' }
                  else if(busObj.sortOrder == 20){ sortOrderName = 'Top Rated' }
                  else if(busObj.sortOrder == 30){ sortOrderName = 'Popular' }
                  else if(busObj.sortOrder == 40){ sortOrderName = 'New' }
                  else if(busObj.sortOrder == 50){ sortOrderName = 'Other' }
                  else if(busObj.sortOrder == 60){ sortOrderName = 'BRB' }
                  else if(busObj.sortOrder == 55){ sortOrderName = 'Pre-Order' }

                  let overallRating = busObj.overallRating
                  let reviews = busObj.reviews.length
                  let uniqueReviewsCount = busObj.uniqueReviewsCount
                  // let region = busObj.regionBelongsTo.name ?busObj.regionBelongsTo.name : "NA";
                  // console.log("......busObj======",busObj);
                  //  if(busObj.accountManagerDetails.length ==)
                  let accManager ="";
                  let accManagerUsername = "";
                  
                    if( Array.isArray(busObj.accountManagerDetails) ){ 
                      if(busObj.accountManagerDetails.length == 0) {
                        accManager= "NA";
                        accManagerUsername="" }
                    }else if(busObj.accountManagerDetails && busObj.accountManagerDetails != null){ accManager = busObj.accountManagerDetails.firstName +" " + busObj.accountManagerDetails.lastName ;
                      accManagerUsername = "(" + busObj.accountManagerDetails.userName + ")" };
                    //  console.log("accManager=====",accManager );    
                  
                

                //  busObj.accountManagersDetails[busObj.accountManagersDetails.length-1].username :"NA";
                  return (
                    <TableRow className={classes.tableRow} hover key={`tableRow_${busObj.businessId}_${index}`}  button="true" >
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" onClick={(e) => this.handleListItemClick(busObj, e)}>
                        {busObj.businessName}
                      </TableCell>
                      {this.props.user.role === "areaManager" ? <TableCell style={{ paddingLeft: "0px 0px 0px 12px" }}>
                                            <IconButton
                                              style={{
                                                float: "center",
                                                padding: "0px",
                                                background: "none"

                                              }}
                                              onClick={(e) => this.openConsumerCountModalOpen(busObj)}
                                            >
                                              <Avatar style={{
                                                background: "none"

                                              }}>
                                                <LINK />
                                              </Avatar>
                                            </IconButton>
                                          </TableCell> : <TableCell></TableCell>}
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.businessId}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{ownerName}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.primaryContactNumber}</TableCell>
                      {/* <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{categoryTypeName}</TableCell> */}
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{categoryName}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{paymentStatus}</TableCell>
                      {/* <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{moment(activeContract ? activeContract.endDate : new Date()).format('DD MMM YYYY')}</TableCell> */}
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{activeContract ? moment(activeContract.endDate).format('DD MMM YYYY') : "NA"}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.businessagreements && busObj.businessagreements.length !== 0 && busObj.businessagreements[0].endDate ? moment(busObj.businessagreements[0].endDate).format('DD MMM YYYY') : "NA"}</TableCell>
                      <TableCell className={classes.tableCellSmall} align="left">
                        {this.props.user.role === "areaManager" ? 
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyAvailabilitySwitch_${busObj.businessId}_${index}`}
                              checked={busObj.availability.status === 'ACTIVE'}
                              onChange={(e) => this.handleXirifyAvailabilitySwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.availability.status === 'ACTIVE' ? 'black' : 'red' }}
                          label={busObj.availability.status === 'ACTIVE' ? 'Open' : 'Closed'}
                        /> : busObj.availability.status === 'ACTIVE' ? 'Open' : 'Closed'
                        }
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} align="left">
                        {this.props.user.role === "areaManager" ? 
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyPreOrderSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.isPreOrder}
                              onChange={(e) => this.handleXirifyPreOrderSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.isPreOrder ? 'black' : 'red' }}
                          label={busObj.isPreOrder ? 'Yes' : 'No'}
                        /> : busObj.isPreOrder ? 'Yes' : 'No'
                        }
                      </TableCell>
                      <TableCell className={classes.unclickableTableCellSmall} align="left">
                        {this.props.user.role === "areaManager" ? 
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`SendQuoteSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.isSendQuote}
                              onChange={(e) => this.handleSendQuoteSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.isSendQuote ? 'black' : 'red' }}
                          label={busObj.isSendQuote ? 'Yes' : 'No'}
                        /> : busObj.isSendQuote ? 'Yes' : 'No'
                        }
                      </TableCell>

                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{(busObj.resumeBusinessTime && busObj.resumeBusinessTime!=null && busObj.resumeBusinessTime!=undefined && busObj.availability.status === 'INACTIVE' ) ? moment.utc(busObj.resumeBusinessTime).format('DD MMM YYYY hh:mm A') : "NA"}</TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyAssuredSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.xirifyAssured}
                              onChange={(e) => this.handleXirifyAssuredSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.xirifyAssured ? 'black' : 'red' }}
                          label={busObj.xirifyAssured ? 'Yes' : 'No'}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyReferralSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.xirifyReferral}
                              onChange={(e) => this.handleXirifyReferralSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.xirifyReferral ? 'black' : 'red' }}
                          label={busObj.xirifyReferral ? 'Yes' : 'No'}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isDeliveryPartnerAllowed && busObj.isDeliveryPartnerAllowed == true ? 'Yes' : 'No'}
                        {/* <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key ={index}
                              checked={busObj.isDeliveryPartnerAllowed}
                              onChange={(e) => this.handleDeliveryPartnerAvailabilitySwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.isDeliveryPartnerAllowed ? 'black' : 'red' }}
                          label={busObj.isDeliveryPartnerAllowed  ? 'Yes' : 'No'}
                        /> */}
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isDeliveryByPartner && busObj.isDeliveryByPartner == true ? 'Yes' : 'No'}  
                      </TableCell>
                      {/* <TableCell align="left" className={classes.unclickableTableCellSmall}> {busObj.address.location.coordinates[1]} </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}> {busObj.address.location.coordinates[0]} </TableCell> */}
                      {this.props.user.role === "admin" ? 
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyReferralSwitch_${busObj.businessId}_${index}`}
                              checked={'isExotel' in busObj && (busObj.isExotel === true || busObj.isExotel === false) ? busObj.isExotel: true}
                              onChange={(e) => this.handleExotelSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: 'isExotel' in busObj && (busObj.isExotel === true || busObj.isExotel === false) ? busObj.isExotel === true && 'black' || busObj.isExotel === false && 'red': 'black' }}
                          label={'isExotel' in busObj && (busObj.isExotel === true || busObj.isExotel === false) ? (busObj.isExotel === true ? 'Yes' : 'No'): 'Yes'}
                        />
                      </TableCell> 
                      :
                      <TableCell className={classes.tableCellSmall} >{'isExotel' in busObj && (busObj.isExotel === true || busObj.isExotel === false) ? (busObj.isExotel === true ? 'Yes' : 'No'): 'Yes'}</TableCell>
                      }
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}> { busObj.isOnlyPostPaymentAllowed ? "Post-Payment" : busObj.isOnlyPrePaymentAllowed ? "Pre-Payment" : busObj.isPostPaymentAllowedToLoyalConsumers ? "Post-Payment For Loyal Shoppers" : "NA"} </TableCell>
                      
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isTypeOrderEnabled && busObj.isTypeOrderEnabled == true ? 'Yes' : 'No'}
                       </TableCell>
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}> { overallRating ? overallRating : "NA"} </TableCell>
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}> { reviews ?  reviews : "NA"} </TableCell>
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}> { uniqueReviewsCount ?  uniqueReviewsCount : "NA"} </TableCell>
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}>
                       <Typography variant="body2" gutterBottom >
                          {busObj.areaBelongsTo && busObj.areaBelongsTo.name ? busObj.areaBelongsTo.name : "Not Assigned"}
                        </Typography>
                         {
                          this.props.user && this.props.user.role === 'regionalHead' ?
                            <EditIcon onClick={(e) => this.assignAreaModalOpen(busObj, e)} /> : null
                        }
                     
                      </TableCell>
                       <TableCell align="left" className={classes.unclickableTableCellSmall1}> {  accManager ? accManager + " " + accManagerUsername: "NA"} </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall1}> {busObj.sortOrder ? sortOrderName + ' (' + busObj.sortOrder + ') ': "NA"} </TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>
                          <Grid className={classes.businessTagsContainer}>
                          {busObj.businessTagIds && busObj.businessTagIds.map((eachSelectedBusinessTag)=>this.props.user && this.props.user?.role !== 'regionalHead'?<Chip
                                  className={classes.chip}
                                  label={eachSelectedBusinessTag.name}
                                  key={eachSelectedBusinessTag._id}
                                  variant="outlined"
                                  deleteIcon={<CancelIcon color="primary"/>}
                                  onDelete={() =>this.handleDeleteBusinessTag(busObj._id,eachSelectedBusinessTag._id,busObj.businessTagIds)}
                                />:<Chip
                                className={classes.chip}
                                label={eachSelectedBusinessTag.name}
                                key={eachSelectedBusinessTag._id}
                                variant="outlined"
                              />)}
                                {this.props.user && this.props.user?.role === 'regionalHead' && (busObj?.businessTagIds?.length===0 || busObj?.businessTagIds===undefined || busObj?.businessTagIds===null)?<Typography className={classes.tableCellSmall}>No Tags Assigned</Typography>:null}

                                {this.props.user && this.props.user.role !== 'regionalHead'?<Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  // startIcon={<AddIcon />}
                                  onClick={()=>this.handleClickAddTag(busObj._id,busObj.businessTagIds)}
                                >
                                  <AddIcon style={{fontSize:'18px'}}/>
                                <Typography style={{fontSize:'12px',}}>ADD TAG</Typography>
                                </Button>:null}
                          </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={this.props.business.busApprovedListCount}
                    rowsPerPage={this.state.limit}
                    page={this.state.pageNumber}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    style={{ overflow: 'unset' }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        <Backdrop className={classes.backdrop} open={this.props.business.isFetching}> <CircularProgress color="inherit" /> </Backdrop>


          <Modal
            className={classes.modal}
            open={this.state.assignAreaModalOpen}
            onClose={this.assignAreaModalClose}
            closeAfterTransition>
            <Container className={classes.assignAreaModalContainer}>
              <AssignAreaForm
                onSubmit={this.assignArea}
                onClose={this.assignAreaModalClose}
                selectedBusiness={this.state.selectedBusiness}
                areas={this.props.areasByRegions} />
            </Container>
          </Modal>
          <Dialog open={this.state.consumerModalOpen} onClose={this.closeConsumerCountModalOpen}>
          <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD", textAlign: "center" }}>
                      {this.state.businessId}   {this.state.businessName}</DialogTitle>
                  <DialogContent style={{"display" : "flex"}}>
                  <Grid item xs={4}>
                  <Typography style={{paddingTop :'25px', marginLeft: "60px"}}>Radius(km)</Typography>
    </Grid> 
    
                  <Grid item xs={3}>
                              <FormControl fullWidth margin="dense" variant="outlined"  className={classes.formControl}  style={{ width: "225px", marginLeft:"60px" }}>                                      
                              <TextField margin="dense" name="businessRadius" variant="outlined"
                                    pattern="[0-9]"
                                    style={{ width: "100px" }}
                                    inputmode="numeric"                                    
                                    value={this.state.businessRadius}
                                    onChange={this.handleChange}
      
                                     />
                              </FormControl>
                          </Grid>
                          <Grid item xs={5}></Grid>   
               
            
                     
                  </DialogContent>
                                     
               {this.state.calculatedConsumerCountModalOpen ? <Container style={{  marginleft : "75px", padding: "17px 41px", width: "auto"}}>Consumer Count for radius {this.state.businessRadius} is {this.state.currentConsumerCount}</Container> : null}  

            <Container style={{ padding : '10px 142px'}}>

              <Button onClick={this.getConsumerCount} variant="contained" color="primary" >
                Get Consumer Count
              </Button>
            </Container>

           
        </Dialog>
        <Dialog
              className={classes.modalBusinessTag}
              open={this.state.isBusinessTagModalOpen}
              onClose={this.handleBusinessTagModalClose}
              closeAfterTransition
              fullWidth={true}
              maxWidth={false}
              PaperProps={{
                style: {
                  width: '350px',
                  height: '240px',
                },
              }}
            >
              <DialogTitle className={classes.headerBar} id="form-dialog-title">
                <div>
                Assign Business Tag/s
                  <CloseIcon
                    className={classes.headGridTwoButton}
                    onClick={this.handleBusinessTagModalClose}
                  />
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialogcontent}>
              <FormControl variant="outlined" fullWidth className={classes.formControl} >
                          <InputLabel id="mutiple-select-label">
                          Select Business Tag
                            </InputLabel>
                            <Select
                              label="Select Business Tags"
                              labelId="mutiple-select-label"
                              multiple
                              value={this.state.selectedBusinessTags}
                              onChange={this.handleSelectedTags}
                              renderValue={(selected) => selected.join(",")}
                              MenuProps={MenuProps}
                                style={ {
                                  width: '300px',
                                }}
                            
                            >
                              {this.state.businessTags?.length && this.state.businessTags?.map((eachBusinessTag, i) => (
                                <MenuItem key={eachBusinessTag.name} value={eachBusinessTag.name}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        this.state.selectedBusinessTags.indexOf(eachBusinessTag.name) >
                                        -1
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={eachBusinessTag.name} />
                                </MenuItem>
                              ))}
                            </Select>
                </FormControl>
                </DialogContent>
                <DialogActions  >
                <Container className={classes.footer}>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      margin="normal"
                      onClick={this.handleBusinessTagModalClose}
                    >
                      cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      ///style={{ padding: "20px" }}
                      margin="normal"
                      color="primary"
                      onClick={()=>this.handleAddTagSubmit(this.state.selectedBusinessId)}
                    >
                      save
                    </Button>
                  </div>
                </Container>
                </DialogActions>
            </Dialog>
        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
          {muiAlert}
        </Snackbar>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
    user: state.auth.user,
    categories: state.categories.categoriesByType,
    region:state.region.regions,
    areasByRegions: state.area.areasByRegions,
  }
};

export default connect(mapStateToProps, { updateDeliveryPartnerAvailability,
  getAreasByRegions,getBusinessListApproved, changeHeaderName, showApprovedBusinessDetails, searchApprovedBusiness, getRegionsRecords,getUsersRecords,updateBusiness,updateExotelForBusinesses,getCategoriesByType, getBusinessExportToExcel,
  updateBusinessStatus, requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,updateBusinessWithArea,clearMsg,getConsumerCount,requestBusinessDetailsRecords,requestBusinessDetailsRecordsFalse,addApprovedBusinessTag })(withStyles(styles)(ApprovedBusiness));
